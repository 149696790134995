<template>
  <div></div>
</template>
<script>
export default {
  head: {
    title: function () {
      return {
        inner: 'Blank page'
      }
    }
  }
}
</script>
